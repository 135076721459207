.formContainer {
  form {
    padding-top: 40px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title {
        //styleName: Text/textHead 16px-24px-normal-500;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      .description {
        //styleName: Text/textBody 16px-24px-normal-400;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 32px 0 0 0;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 16px;

    form {
      max-height: 100%; //calc(100% - 82px); // navigation.height + navigation.margin
      padding: 20px 32px 24px 32px;
      margin: auto 0;
      overflow-y: auto;

      h1 {
        font-size: 18px;
      }

      .question {
        .questionText {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
        .footerAnswer {
          display: flex;
          justify-content: space-between;
          flex-direction: row;

          .boolAnswers {
            align-self: end;
            margin-top: auto;
            width: 24%;
            max-width: 300px;
          }
        }
      }
    }

    .navigation {
      width: 72vw;
      max-width: 1328px;
      padding-left: 36vw;
    }
  }

  @media screen and (min-width: 1920px) {
    form {
      h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
