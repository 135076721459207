.ghgForm {
  width: 100%;

  .ghgQuestion {
    display: flex;
    gap: 16px;
    flex-direction: column;

    .title {
      //styleName: Heading/Desktop/H5 24px-36px-normal-600;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .answerRow {
    display: flex;
    flex-direction: row;
    gap: 16px;
    .item {
      max-width: 400px;
      margin-bottom: 0;
    }
  }
}
