.fileContainer {
  .fileRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    .fileName {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: auto;

      //styleName: Text/textHead 16px-24px-normal-500;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      .text {
        overflow-wrap: break-word;
        width: auto;
        max-width: 530px;
      }
      .svg {
        flex-shrink: 0;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      .descriptionBtn {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        // > svg {
        //   width: 20;
        //   height: 20;
        //   flex-shrink: 0;
        //   font-size: 20;
        // }
      }
    }
  }
  .description {
    text-wrap: wrap;
    padding: 8px 16px 16px 36px;
    overflow-wrap: break-word;

    //styleName: Text/textHead 16px-24px-normal-500;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
