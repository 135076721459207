.filesModal {
  .question {
    //styleName: Heading/Desktop/H6 20px-32px-normal-600;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
  .subquestion {
    //styleName: Heading/Desktop/H7 16px-32px-normal-600;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .filesWrapper {
    margin: 16px 0;
    overflow-y: auto;
    max-height: 60vh;
    height: fit-content;
    .filesContainer {
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .buttonBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
