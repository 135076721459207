.main {
  width: 100%;
  height: 100%;
  padding: 0 30px 0 53px;
  display: flex;
  flex-direction: row;
  column-gap: 74px;
  .navigator {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .scrollable {
      padding-right: 8px;
      margin-right: -16px;
      width: 100%;
    }
  }
  .mainPanel {
    z-index: 1;
    width: 100%;
    display: flex;
    gap: 32px;
    flex-direction: column;

    .questionWrapper {
      border-radius: 16px;
      padding: 32px 40px 32px 40px;
      gap: 16px;
      box-shadow: 0px 4px 24px 0px #00000014;
      background-color: white;
      z-index: 1;
    }
    .buttonWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 24px;
    }
  }
}
