$navyColor: #18397a;
$whiteColor: #fff;
$lightGrayColor: #efefef;

.profileContainer {
  height: 100%;
  overflow-y: auto;
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 45px 24px 32px 24px;

    .infoBlock {
      border: 1px solid #efefef;
      border-radius: 12px;
      padding: 12px 20px 12px 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      margin-bottom: 32px;

      > div {
        display: flex;
        flex-direction: row;
        gap: 10px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .info {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #212121;
          flex: 0 0 200px;
          @media screen and (max-width: 768px) {
            flex: 0 0;
          }
        }
        .text {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #212121;
        }
      }
    }
    .progressBar {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 22%;
      width: 100%;
      height: 8px;
    }

    form {
      margin-top: 20px;
      h1 {
        font-size: 20px;
        line-height: 24px;
        margin-top: -12px;
        color: $navyColor;
      }

      .disclaimerBtn {
        width: 100%;
        justify-content: flex-start;
        text-transform: unset;
        margin-bottom: 8px;
      }

      .input {
        margin-bottom: 8px;
        width: 100%;
      }

      .docBtn {
        margin-top: 12px;
        width: 100%;
      }
    }
    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 500px;
      margin-top: 32px;

      &.single {
        justify-content: flex-end;
      }
    }

    @media screen and (min-width: 768px) {
      justify-content: center;
    }

    @media screen and (min-width: 1024px) {
      .progressBar {
        top: 40%;
        height: 18px;
      }

      form {
        justify-content: space-between;

        h1 {
          font-size: 22px;
          line-height: 26px;
        }

        .disclaimerBtn {
          margin-bottom: 16px;
        }

        .input {
          margin-bottom: 20px;
          width: calc(50% - 8px);
        }

        .docBtn {
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          width: auto;
          margin-top: 4px;
        }
      }

      .navigation {
        width: 60%;
        max-width: 1104px;
        margin-top: 40px;
      }
    }
    @media screen and (min-width: 1440px) {
      form {
        h1 {
          font-size: 24px;
          line-height: 28px;
          margin-top: -12px;
        }

        .wide {
          width: calc(39% - 11px);
        }
        .normal {
          width: calc(29% - 11px);
        }
        .docBtn {
          margin: 0;
          margin-bottom: 20px;
        }
      }
    }

    @media screen and (min-width: 1920px) {
      form {
        h1 {
          font-size: 28px;
          line-height: 32px;
          margin-top: -14px;
        }
      }
    }
  }
}
.modalDraftList {
  margin-top: 12px;

  @media screen and (min-width: 1024px) {
    margin-top: 24px;
  }
}

.nativeReactTextInput {
  outline: none !important;
  border: 1px solid #ccc;
  font-size: 16px;
  line-height: 24px;
  border-radius: 16px;
  color: #212121;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 50px;
  background: #fff;
  padding: 12px 20px;
  width: 100%;

  &:hover {
    outline: none !important;
    border: 1px solid #18397a;
  }
  &:focus {
    outline: none !important;
    border: 1px solid #18397a;
  }
}

.alertBB {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;

  button {
    height: 50px;
    flex: 1 1 auto;
    max-width: 100%;
  }
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
}
