.__modalRoot {
  &.modalWide {
    max-width: 980px;
    margin: auto;
    > div {
      > div {
        max-width: 100% !important;
      }
    }
  }
}

.modal {
  width: fit-content !important;
  max-width: fit-content !important;
  > div {
    > h3 {
      width: 100%;
    }
  }
  .headerModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    button {
      color: #d32f2f;
      &:hover span svg path {
        fill: #d32f2f;
      }
    }
  }
  .modalContent {
    width: 100%;
    .questionInput {
      margin-top: 16px;
      width: 100%;
      > div {
        > textarea {
          border-radius: 0;
        }
      }
    }
    .subheader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-content: center;
      > button {
        height: fit-content;
        margin: auto 0;
      }
    }
  }
  .modalButtonsBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    gap: 24px;
    button {
      padding: 0 24px !important;
      max-width: fit-content;
    }
  }
}
