.panelStyle {
  border-bottom: none !important;
  margin-bottom: 24px;
  .collapseText {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  > div {
  }
}
.topLevel {
  > div {
    background: #8b9cbd1f;
    padding: 10px 10px;
  }
}
.childrenContainer {
  .nestedCollapse {
    > div {
      > div {
        padding-left: 0 !important;
      }
    }
  }
  .linkListContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .linkItem {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .linkButton {
        margin-right: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        border: none;
        background-color: transparent;
        //styleName: Text/textHead 16px-24px-normal-500;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .icon {
        min-width: 24px;
      }
    }
  }
}
.selected {
  color: #18397a;
}
